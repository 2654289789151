
import { defineComponent, onMounted } from 'vue';
import TotalCount from '@/views/DashBoard/TotalCount.vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { VueCookieNext } from 'vue-cookie-next';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import TaskWidget from "@/components/widgets/admin/TaskWidget.vue";

export default defineComponent({
  name: 'dashboard',
  components: {
    TotalCount,
    TaskWidget,

  },
  data() {
    return {
      message: [],
      showNotification: false,
      notification_message: '' as any,
      componentKey: 0 as any,
      data: {},
      course: {},
      loading: true,
      tableData: [],
      load: true,
      entity_type_id: '',
      adminPermission: false,
      userInfo: {
        employee: {
          entity_organogram: [],
        } as any,
      },
      entity_id: "",
      userRole: "",
      institute_info_id: "",
      association_count: "",
      training_institute_count: "",
      course_count: "",
      trainer_count: "",
      assessor_count: "",
      running_batch: "",
      pending_enrollment: "",
      pending_attendance: "",
      pending_employment: "",
      pending_entity_count: "",
      pending_institute_count: "",
      pending_course_count: "",
      pending_assessor_count: "",
      pending_trainer_count: "",
      pending_map_trainer_institute_count: "",
      pending_map_trainer_course_count: "",
      pending_map_assessor_institute_count: "",
      pending_map_assessor_course_count: "",
      pending_trainee_count: "",
      pending_trainning_target_count:"",
      pending_assessment: "",
      pendingInstituteTarget: "",
      isActive: false,
      institute_cls: false,
      role_id: "" as any,
      taskCount: '' as any,
      running_task: "" as any,
      pending_task: "" as any,
      complete_task: "" as any,
      user_id: "" as any,
      group_id: "" as any,
    };
  },
  async created() {
    this.role_id = VueCookieNext.getCookie('_seip_role_id');
    this.user_id = VueCookieNext.getCookie('_seip_employee_id');
    this.group_id = VueCookieNext.getCookie('group_id');
    await this.getTaskCount();
    let day_diff = VueCookieNext.getCookie('day_diff');

    if (day_diff != null && day_diff <= 10) {
      this.notification_message = VueCookieNext.getCookie(
        'notification_message'
      );
      this.showNotification = true;
    }

    //await this.getMessageData();
  },
  methods: {
    async getTaskCount() {
        await ApiService.get("configurations/task/count?assigned_to=" + this.user_id + "&group_id=" + this.group_id)
          .then((response) => {
            this.taskCount = response.data.data;
            console.log(this.taskCount);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },

    destroyMessage() {
      VueCookieNext.removeCookie('day_diff');
      VueCookieNext.removeCookie('notification_message');
      this.notification_message == '';
      this.showNotification = false;
    },
    async getMessageData() {
      await ApiService.get('configurations/day_message/active')
        .then((response) => {
          this.message = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
