
import { defineComponent, onMounted, ref } from 'vue';
import { MenuComponent } from '@/assets/ts/components/MenuComponent';
import { getCSSVariableValue } from '@/assets/ts/_utils';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';

export default defineComponent({
  name: 'dashboard',
  components: {},
  props: {
    taskCount: "" as any,
  },
  data() {
    return {
      role_id: '' as any,
    };
  },
  async created() {
    this.role_id = VueCookieNext.getCookie('_seip_role_id');
    
  },
  methods: {
 

  },
});
