import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row bg-light mb-5" }
const _hoisted_2 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6 pe-0" }
const _hoisted_3 = { class: "row gy-5 g-xl-8" }
const _hoisted_4 = { class: "col-xxl-12 md-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TaskWidget = _resolveComponent("TaskWidget")!
  const _component_TotalCount = _resolveComponent("TotalCount")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TaskWidget, {
          taskCount: _ctx.taskCount,
          description: "Active Projects"
        }, null, 8, ["taskCount"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_TotalCount, {
          taskCount: _ctx.taskCount,
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "success",
          "chart-height": "200",
          "stroke-color": "#cb1e46"
        }, null, 8, ["taskCount"])
      ])
    ])
  ], 64))
}