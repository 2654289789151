
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: "task-count-widget",
  props: {
    taskCount: "" as any,
  },
  data() {
    return {
      role_id: "" as any,
    };
  },
  async created() {
    this.role_id = VueCookieNext.getCookie('_seip_role_id');
  },
  methods: {
   

  },
});
